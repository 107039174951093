<template>
  <v-form lazy-validation>
    {{errorMessage}}
    <v-container>
      <v-col md="4" offset-md="4">
        <h1>Register</h1>

        <v-row>
          <v-text-field label="Username" v-model="username" autocomplete="username" required>
          </v-text-field>
        </v-row>
        <v-row>
          <v-text-field label="Email" v-model="email" autocomplete="email" required>
          </v-text-field>
        </v-row>
        <v-row>
          <v-text-field label="Password" type="password" v-model="password" autocomplete="current-password" required>
          </v-text-field>
        </v-row>
        <v-row>
          <v-text-field label="Registration Token" v-model="registrationToken" required>
          </v-text-field>
        </v-row>
        <v-row>
          <v-btn color="success" v-on:click="login(username, email, password, registrationToken)">
            Register!
          </v-btn>
        </v-row>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>

let baseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
}else{
  baseURL = 'http://localhost:8080'
}


export default {
  name: "Login",
  data: function (){
    return {
      email: "",
      username: "",
      password: "",
      registrationToken: "",
      errorMessage: "",
    }
  },
  methods: {
    login: function(username, email, password, registrationToken){
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({user: username, email: email, password:password, registration_token: registrationToken})
      };
      fetch(baseURL+"/addUser", requestOptions)
        .then(response => {
          response.json().then(data => {
            if (!response.ok){
              console.error("failed to add user: " + data)
              return
            }
            const userAdded = data['addedUser']

            if (userAdded){
              this.$router.push({name: "Login", params: {display: 'Used successfully created. You can now login'}})
            }

            console.log(data)

          })
        })

    }
  }
}
</script>

<style scoped>

</style>
